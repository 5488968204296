import React, { useEffect, useState } from "react";
import "./Careers.scss";
import axios from "axios";
import Papa from "papaparse";

// Define the Job type for TypeScript
interface Job {
  title: string;
  description: string;
  endDate: string;
  status: string;
  employmentType: string;
  locationType: string;
}

const Careers: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(
          "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ-qYLd5Y6f7VplxNJfGmQVOQuMm42nRaI-6WEOIQSKvkj7ApjP_SFmHJkF_SeBdPCcPVVgqL413sIa/pub?gid=0&single=true&output=csv"
        );

        // Parse the CSV data using PapaParse
        Papa.parse(response.data, {
          header: true,
          skipEmptyLines: false,
          transformHeader: (header) => header.trim(),
          complete: (result) => {
            const parsedJobs: Job[] = result.data.map((row: any) => ({
              title: row["Job Position"]?.trim() || "",
              description: row["Job Overview"]?.trim() || "",
              endDate: row["Job Post End Date"]?.trim() || "",
              status: row["Status"]?.trim() || "",
              employmentType: row["Employment types"]?.trim() || "",
              locationType: row["Location types"]?.trim() || "",
            }));

            // Filter out jobs where status is "Archived"
            const filteredJobs = parsedJobs.filter(
              (job) => job.status !== "Archived"
            );
            setJobs(filteredJobs);
          },
          error: (err) => {
            console.error("Error parsing CSV:", err);
          },
        });
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  // Modified function to return a single paragraph
  const formatDescription = (description: string) => {
    return <p className="job-description">{description}</p>;
  };

  // Function to conditionally render column titles and values
  const renderColumn = (title: string, value: string) => {
    if (value && value.length > 0) {
      return (
        <p>
          <strong>{title}:</strong> {value}
        </p>
      );
    }
    return null; // Don't render if value is missing
  };

  return (
    <section id="careers" className="container">
      <span className="section-caption">Jobs</span>
      <h2 className="mb-5">Open roles at Daysketcher</h2>
      <div className="job-list">
        {jobs.map((job, index) => (
          <div className="job-card" key={index}>
            <div className="d-flex align-items-baseline flex-wrap">
              <h5>{job.title}</h5>
              <ul className="job-types">
                <li>{job.locationType}</li>
                <li>{job.employmentType}</li>
              </ul>
            </div>
            {renderColumn("Valid until", job.endDate)}
            {formatDescription(job.description)}
            <button
              className="btn btn-dark"
              onClick={() =>
                (window.location.href = `mailto:careers@daysketcher.com?subject=Apply for ${encodeURIComponent(
                  job.title
                )}`)
              }
            >
              Apply
              <i className="fa fa-arrow-right ms-2"></i>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Careers;
