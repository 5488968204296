import React from "react";

const FAQ = () => {
  return (
    <section id="faq" className="container">
      <span className="section-caption">FAQ</span>
      <h2 className="mb-5">
        Got Questions?
        <br />
        Look here.
      </h2>
      <div className="grid">
        <div className="g-col-md-8 g-col-12 g-start-md-3">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What devices is Daysketcher compatible with?
                </button>
              </h3>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Daysketcher is cross-platform, which means it will be
                  available on iOS and Android.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Can I access Daysketcher on multiple devices?
                </button>
              </h3>
              <div
                id="collapseTwo"
                aria-labelledby="headingTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Yes, once the app is available for download, users will be
                  able to access their account and memories from multiple
                  devices.{" "}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Is Daysketcher free?
                </button>
              </h3>
              <div
                id="collapseThree"
                aria-labelledby="headingThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  The app is not currently available on the App Store or Google
                  Play Store. However, you can secure early access by joining
                  our beta waiting list. By signing up today, you'll receive a
                  complimentary 6 months free subscription. In the future, when
                  we introduce our subscription model, the price will be €29.99
                  per year ( or €2.99 per month). Additionally, there will still
                  be a Freemium version available, although some features will
                  be limited.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Can I share my memories with others on Daysketcher?
                </button>
              </h3>
              <div
                id="collapseFour"
                aria-labelledby="headingFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Yes, the app will have sharing options that allow users to
                  share memories with friends and family. Users can choose to
                  share memories publicly or privately with specific
                  individuals.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Can Daysketcher help me with mental health issues?
                </button>
              </h3>
              <div
                id="collapseFive"
                aria-labelledby="headingFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  While Daysketcher is not a replacement for professional mental
                  health treatment, it can be a helpful tool for improving
                  overall well-being and managing stress and anxiety. Users with
                  mental health concerns should seek professional help in
                  addition to using the app.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
