import React from "react";

const DownloadApp = () => {
  return (
    <section id="download" className="constainer-fluid">
      <img
        src="assets/images/daysketcher-logo.svg"
        alt="Daysketcher Logo"
        width="100%"
        height="32px"
      />
      <h2 className="mt-4 mb-3 mx-3">
        Want to start reflecting on your memories today?
      </h2>
      <div className="col-md-6 col-12 mx-md-auto px-3">
        <p className="text-center">
          Download our free beta app experience, featuring digital journaling
          and positive memory reflections!
        </p>
        <div className="d-flex justify-content-center align-items-center mt-5 flex-sm-row gap-5 flex-column">
          <div className="d-flex flex-column gap-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.daysketcher.android&pcampaignid=web_share"
              id="playStoreButton"
            >
              <img
                src="assets/images/play-store.png"
                height={"50px"}
                alt="Download Daysketcher application from Play store"
              />
            </a>
            <a
              href="https://apps.apple.com/mk/app/daysketcher/id6504233200"
              id="appStoreButton"
            >
              <img
                src="assets/images/app-store.png"
                height={"50px"}
                alt="Download Daysketcher application from App store"
              />
            </a>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <img
              src="assets/images/daysketcher-qr-code.svg"
              alt="QR Code to download Daysketcher application from your store."
              height={"124px"}
              className="qr-code"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
